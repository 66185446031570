body {
	//background-color: black;
	font-family: -apple-system, system-ui, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;
	//color: white;
}
* {
	//color: white;
}

a {
	//color: white;
}
a:hover {
	//color: orange;
}

nav {
	margin: 10px;

	ul {
		display: flex;
		list-style-type: none;
		padding: 0;
		margin: 0;

		li {
			padding: 8px;
		}
	}
}
